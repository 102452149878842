<template>
	<div class="animated fadeIn" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<b-form @submit.prevent="submitForm">
			<!-- <pre>{{ accomodation }}</pre> -->
			<!-- <pre>eventFile: {{ eventFile }}</pre>
			<pre>{{ originalAccommodation }}</pre> -->
			<b-row class="d-flex justify-content-start align-items-center pb-2">
				<b-col cols="6">
					<!-- <b-button size="md" variant="secondary" class="w-138-px" block v-if="enableBtnReport">{{ FormMSG(1, 'Reports') }}</b-button> -->
					<div class="wrap-status justify-content-start">
						<div :class="`status ${accomodation.statusClass}`" style="font-size: 0.7rem">
							{{ getTextOption(accomodation.status, statusOptions) }}
						</div>
					</div>
				</b-col>
				<b-col cols="6">
					<div class="d-flex justify-content-end align-items-center">
						<div class="text-booking font-weight-bold mr-3">{{ FormMSG(1, 'Booking n°') }}</div>
						<div class="number-booking py-2 px-4 font-weight-bold">{{ accomodation.acc.reference }}</div>
					</div>
				</b-col>
			</b-row>
			<b-row class="mt-2 pb-2">
				<b-col>
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ 'General' }}
						</legend>
						<b-row class="pb-2">
							<b-col sm="12" md="12" lg="4" xl="4">
								<b-form-group :label="FormMSG(2, 'Name')" v-if="action === 'UPDATE'">
									<div class="d-flex flex-column content-form-text" style="align-items: normal">
										<b>{{ accomodation.user.firstName }} {{ accomodation.user.name }}</b>
										<div class="text-department">{{ accomodation.user.departmentName }}</div>
									</div>
								</b-form-group>
								<b-form-group :label="FormMSG(49, 'Publisher')" v-else>
									<div class="d-flex flex-column content-form-text" style="align-items: normal">
										<b>{{ accomodation.publisher.firstName }} {{ accomodation.publisher.name }}</b>
										<div class="text-department">{{ accomodation.publisher.departmentName }}</div>
									</div>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="8" xl="8">
								<b-form-group :label="FormMSG(3, 'Description')">
									<div class="content-form-text">{{ accomodation.acc.description }}</div>
								</b-form-group>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row class="mt-2 pb-2">
				<b-col>
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ FormMSG(4, 'Booking') }}
						</legend>
						<b-row>
							<b-col
								:sm="`${accomodation.acc.category === 1 ? 6 : 12}`"
								:md="`${accomodation.acc.category === 1 ? 6 : 12}`"
								:lg="`${accomodation.acc.category === 1 ? 3 : 4}`"
								:xl="`${accomodation.acc.category === 1 ? 3 : 4}`"
							>
								<b-form-group :label="FormMSG(44, 'Category')">
									<div class="content-form-text">{{ getTextOption(accomodation.acc.category, categoryOptions) }}</div>
								</b-form-group>
							</b-col>
							<b-col v-if="accomodation.acc.category === 1">
								<transport-type-selector
									ref="transportTypeSelectorAccomodation"
									hide-freight-type
									:data-type="accomodation.acc.type"
									:data-detail="accomodation.acc.coTwoType"
									:data-additional-detail="accomodation.acc.bookingStatus"
									disable-form
								/>
							</b-col>
							<b-col v-if="accomodation.acc.category !== 1" sm="12" md="12" lg="4" xl="4">
								<b-form-group :label="FormMSG(6, 'Type')">
									<div class="content-form-text">
										{{
											getTextOption(accomodation.acc.type, accomodation.acc.category === 1 ? travelTypeOptions : accomodationTypeOptions)
										}}
									</div>
								</b-form-group>
							</b-col>
							<b-col v-if="accomodation.acc.category !== 1" sm="12" md="12" lg="4" xl="4">
								<!--								<b-form-group :label="FormMSG(7, 'Co2 Type')" v-if="accomodation.acc.category === 1">-->
								<!--									<div class="content-form-text">{{ getTextOption(accomodation.acc.coTwoType, co2TypeOptions) }}</div>-->
								<!--								</b-form-group>-->
								<b-form-group :label="FormMSG(8, 'No. of bedrooms')">
									<div class="content-form-text">{{ accomodation.acc.nbBedroom }}</div>
								</b-form-group>
							</b-col>
							<!--							<b-col sm="6" md="6" lg="3" xl="3" v-if="accomodation.acc.category === 1">-->
							<!--								<b-form-group>-->
							<!--									<label>&nbsp;</label>-->
							<!--									<div class="content-form-text">{{ getTextOption(accomodation.acc.bookingStatus, bookingStatusOptions) }}</div>-->
							<!--								</b-form-group>-->
							<!--							</b-col>-->
						</b-row>
						<b-row v-if="action === 'UPDATE'">
							<b-col sm="6" md="6" lg="3" xl="3">
								<b-form-group :label="FormMSG(9, 'Start date')">
									<v-date-picker
										v-model="accomodation.startDate"
										:available-dates="{
											start: new Date(),
											end: null
										}"
										:locale="lang"
										:masks="masks"
										@dayclick="handleStartDate"
									>
										<template v-slot="{ inputValue, togglePopover }">
											<b-input-group>
												<b-form-input :value="inputValue" class="br-none" readonly />
												<b-input-group-append>
													<b-input-group-text
														class="cursor-pointer bg-color-white input-group-text-bg-white"
														@click="togglePopover()"
													>
														<component
															:is="getLucideIcon('Calendar')"
															color="rgba(6, 38, 62, .6)"
															:size="18"
															:stroke-width="2.25"
														/>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</template>
									</v-date-picker>
								</b-form-group>
							</b-col>
							<b-col sm="6" md="6" lg="3" xl="3">
								<b-form-group :label="FormMSG(10, 'Start time')">
									<input-date-time-picker
										:uiid="'accst'"
										:type="'text'"
										:placeholder="'hh:mm'"
										:item="accomodation.startTime"
										:nbHour="rangeHour[WORKTYPE.daily]"
										:nbMinute="rangeMinute[WORKTYPE.daily]"
										:isEditable="
											() => {
												return true;
											}
										"
										:fieldName="'startTime'"
										:workType="WORKTYPE.daily"
										iconColor="rgba(6, 38, 62, 0.6)"
										:iconStrokeWidth="2.25"
										:iconSize="18"
										iconBg="cursor-pointer bg-color-white input-group-text-bg-white"
										@change="handleChangeHourMin"
									/>
								</b-form-group>
							</b-col>
							<b-col sm="6" md="6" lg="3" xl="3">
								<b-form-group :label="FormMSG(11, 'End date')">
									<v-date-picker
										v-model="accomodation.endDate"
										:available-dates="{
											start: new Date(),
											end: null
										}"
										:locale="lang"
										:masks="masks"
										@dayclick="handleEndDate"
									>
										<template v-slot="{ inputValue, togglePopover }">
											<b-input-group>
												<b-form-input :value="inputValue" class="br-none" readonly />
												<b-input-group-append>
													<b-input-group-text
														class="cursor-pointer bg-color-white input-group-text-bg-white"
														@click="togglePopover()"
													>
														<component
															:is="getLucideIcon('Calendar')"
															color="rgba(6, 38, 62, .6)"
															:size="18"
															:stroke-width="2.25"
														/>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</template>
									</v-date-picker>
								</b-form-group>
							</b-col>
							<b-col sm="6" md="6" lg="3" xl="3">
								<b-form-group :label="FormMSG(12, 'End time')">
									<input-date-time-picker
										:uiid="'accet'"
										:type="'text'"
										:placeholder="'hh:mm'"
										:item="accomodation.endTime"
										:nbHour="rangeHour[WORKTYPE.daily]"
										:nbMinute="rangeMinute[WORKTYPE.daily]"
										:isEditable="
											() => {
												return true;
											}
										"
										:fieldName="'endTime'"
										:workType="WORKTYPE.daily"
										iconColor="rgba(6, 38, 62, 0.6)"
										:iconStrokeWidth="2.25"
										:iconSize="18"
										:isSubmitted="isSubmitted"
										:error="!$v.accomodation.endTime.hourMinuteValid"
										iconBg="cursor-pointer bg-color-white input-group-text-bg-white"
										@change="handleChangeHourMin"
									/>
									<div v-if="isSubmitted && !$v.accomodation.endTime.hourMinuteValid" class="invalid-feedback">
										{{ FormMSG(13, 'End time must be greater than start time') }}
									</div>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row v-else>
							<b-col sm="12" md="12" lg="6" xl="6">
								<b-form-group :label="FormMSG(9, 'Start date')">
									<div class="content-form-text" style="background: #fbf5e5">
										{{ getLiteralDate(accomodation.startDate, accomodation.startTime) }}
									</div>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="6" xl="6">
								<b-form-group :label="FormMSG(11, 'End date')">
									<div class="content-form-text" style="background: #fbf5e5">
										{{ getLiteralDate(accomodation.endDate, accomodation.endTime) }}
									</div>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row class="pb-2">
							<b-col sm="12" md="12" lg="6" xl="6">
								<b-form-group :label="FormMSG(14, 'Supplier')">
									<div class="content-form-text">{{ accomodation.acc.supplier.name }}</div>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="6" xl="6" v-if="accomodation.confirmation.length > 0">
								<b-row v-if="action === 'UPDATE'">
									<b-col :cols="`${$screen.width > 576 ? 10 : 9}`">
										<b-form-group :label="FormMSG(15, 'Attachment')">
											<b-form-file
												v-model="accomodation.fileToUploaded"
												ref="accomodation-file"
												class="mb-2"
												:accept="acceptFiles"
												:placeholder="'Choose a file or drop it here ...'"
												:browse-text="'Browse'"
												:drop-placeholder="'Drop file here ...'"
												@change="handleChangeFileToUploaded"
											/>
											<!-- :class="{
												'is-invalid': isSubmitted && $v.accomodation.fileToUploaded && $v.accomodation.fileToUploaded.$error
											}" -->
											<!-- <div
												v-if="isSubmitted && $v.accomodation.fileToUploaded && !$v.accomodation.fileToUploaded.required"
												class="invalid-feedback"
											>
												{{ FormMSG(16, 'Please, a document is required') }}
											</div> -->
										</b-form-group>
									</b-col>
									<b-col :cols="`${$screen.width > 576 ? 2 : 3}`" style="margin-top: 28px">
										<b-row>
											<b-button
												class="btn bg-transparent border-0"
												variant="primary"
												size="sm"
												:title="accomodation.fileName"
												v-b-tooltip.hover.top
												@click="viewAttachment"
											>
												<component :is="getLucideIcon('Paperclip')" color="#225CBD" :size="20" />
											</b-button>
											<b-button
												class="btn bg-transparent border-0"
												variant="primary"
												size="sm"
												:title="FormMSG(36, 'Delete')"
												v-b-tooltip.hover.top
												@click="removeAttachment"
											>
												<component :is="getLucideIcon('X')" color="#EA4E2C" :size="22" />
											</b-button>
										</b-row>
									</b-col>
								</b-row>
								<b-row v-else>
									<b-form-group :label="FormMSG(15, 'Attachment')">
										<b-button
											class="btn bg-transparent border-0"
											variant="primary"
											size="sm"
											:title="accomodation.fileName"
											v-b-tooltip.hover.top
											@click="viewAttachment"
										>
											<component :is="getLucideIcon('Paperclip')" color="#225CBD" :size="20" />
										</b-button>
									</b-form-group>
								</b-row>
							</b-col>
							<b-col sm="12" md="12" lg="6" xl="6" v-else>
								<b-form-group :label="FormMSG(15, 'Attachment')" v-if="action === 'UPDATE'">
									<b-form-file
										v-model="accomodation.fileToUploaded"
										ref="accomodation-file"
										class="mb-2"
										:accept="acceptFiles"
										:placeholder="'Choose a file or drop it here ...'"
										:browse-text="'Browse'"
										:drop-placeholder="'Drop file here ...'"
										@change="handleChangeFileToUploaded"
									/>
									<!-- :class="{ 'is-invalid': isSubmitted && $v.accomodation.fileToUploaded && $v.accomodation.fileToUploaded.$error }" -->
									<!-- <div
										v-if="isSubmitted && $v.accomodation.fileToUploaded && !$v.accomodation.fileToUploaded.required"
										class="invalid-feedback"
									>
										{{ FormMSG(16, 'Please, a document is required') }}
									</div> -->
								</b-form-group>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row class="mt-2 pb-2">
				<b-col>
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ accomodation.acc.category === 1 ? FormMSG(17, 'Route') : FormMSG(18, 'Place') }}
						</legend>
						<b-row v-if="accomodation.acc.category === 1">
							<b-col sm="12" md="12" lg="6" xl="6">
								<b-form-group :label="FormMSG(19, 'From address')">
									<div class="content-form-text">{{ accomodation.acc.fromAddress }}</div>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="6" xl="6">
								<b-form-group :label="FormMSG(20, 'To address')">
									<div class="content-form-text">{{ accomodation.acc.toAddress }}</div>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row v-else>
							<b-col sm="6">
								<b-form-group :label="FormMSG(21, 'Accommodation name')">
									<div class="content-form-text">{{ accomodation.acc.name }}</div>
								</b-form-group>
							</b-col>
							<b-col sm="6">
								<b-form-group :label="FormMSG(22, 'Address')">
									<div class="content-form-text">{{ rendAddress(accomodation.acc.address) }}</div>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col sm="12" md="12" lg="3" xl="3" v-if="accomodation.acc.category === 1">
								<b-form-group :label="FormMSG(23, 'Total distance')">
									<b-input-group>
										<b-form-input disabled :value="accomodation.acc.totalDistance" id="total-distance" />
										<b-input-group-append>
											<b-input-group-text style="background: #47c7bf">
												<span class="font-weight-bold" style="color: #fff">{{ distanceUnit }}</span>
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" :lg="`${accomodation.acc.category === 1 ? 9 : 12}`" :xl="`${accomodation.acc.category === 1 ? 9 : 12}`">
								<b-form-group :label="FormMSG(24, 'Special instructions')">
									<div class="content-form-text">{{ accomodation.acc.specialInstruction }}</div>
								</b-form-group>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row class="mt-2 pb-2">
				<b-col>
					<fieldset
						class="my-0 py-0 pb-0"
						:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
						style="background-color: rgba(248, 249, 251, 0.5)"
					>
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ FormMSG(25, 'Communication') }}
						</legend>
						<b-row v-if="accomodation.status === 0">
							<b-col sm="5" md="5" lg="3" xl="3" class="d-flex justify-content-start align-items-center">
								<h6>
									<b>{{ FormMSG(26, 'Publish and Send details by') }}</b>
								</h6>
							</b-col>
							<b-col sm="7" md="7" lg="9" xl="9">
								<div class="divider-dotted" :style="`${$screen.width > 576 ? 'margin: 12px 0 0 0' : 'margin: 4px 0 8px 0'}`" />
							</b-col>
						</b-row>
						<b-row class="d-flex justify-content-center pb-2" v-if="accomodation.status === 0">
							<b-col sm="12" md="12" lg="8" xl="6" offset-sm="0">
								<b-row>
									<b-col sm="4" :class="`${$screen.width > 576 ? 'd-flex justify-content-center' : 'd-flex justify-content-start'}`">
										<b-form-checkbox size="lg" id="toEmail" v-model="accomodation.toEmail" class="pj-cb pb-1" @change="onChangeEmail">
											{{ FormMSG(28, 'Email') }}
										</b-form-checkbox>
									</b-col>
									<b-col sm="4" :class="`${$screen.width > 576 ? 'd-flex justify-content-center' : 'd-flex justify-content-start'}`">
										<b-form-checkbox size="lg" id="toSms" v-model="accomodation.toSms" class="pj-cb pb-1" @change="onChangeSms">
											{{ FormMSG(29, 'Text message') }}
										</b-form-checkbox>
									</b-col>
									<b-col sm="4" :class="`${$screen.width > 576 ? 'd-flex justify-content-center' : 'd-flex justify-content-start'}`">
										<b-form-checkbox
											size="lg"
											id="toWhatsApp"
											v-model="accomodation.toWhatsApp"
											class="pj-cb pb-1"
											@change="onChangeWhatsApp"
										>
											{{ FormMSG(30, 'WhatsApp') }}
										</b-form-checkbox>
									</b-col>
								</b-row>
								<div class="invalid-feedback text-center pb-2" style="margin-top: -2px" v-if="unknownDevice">
									* {{ FormMSG(31, 'Please, select one or more device(s) to send the details.') }}
								</div>
							</b-col>
						</b-row>
						<b-row class="d-flex justify-content-center pb-3" v-else>
							<b-col sm="12">
								<div class="text-purple text-center" v-if="accomodation.status > 0" :class="`${accomodation.status === 0 ? 'pt-1' : 'pt-0'}`">
									{{ FormMSG(34, 'Sent on') }} {{ getPublishedDate() }} {{ getDeviceName().length > 0 ? FormMSG(35, 'via') : '' }}
									{{ getDeviceName() }}
								</div>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					class="d-flex justify-content-center mt-2"
					sm="4"
					md="4"
					lg="4"
					xl="4"
					offset-sm="5"
					offset-md="5"
					offset-lg="5"
					offset-xl="5"
					:style="`display: ${displayAddBtn ? 'block' : 'none'} !important;`"
				>
					<b-button ref="submit" type="submit" size="md" variant="outline-primary" class="w-138-px" block>{{ FormMSG(37, 'Save') }}</b-button>
				</b-col>
			</b-row>
		</b-form>
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import InputDateTimePicker from '@/components/InputDateTimePicker/InputDateTimePicker';
import { useWorktype, useRangeHour, useRangeMinute } from '../../utils/utils';
import moment from 'moment';
import { store } from '@/store';
import addressHelperMixin from '@/components/LocationService/addressHelper.mixin';
import { updateAccomodationDelivery, publishAccomodationDelivery } from '@/cruds/accomodation.crud';
import TransportTypeSelector from '../../modules/transports/components/TransportTypeSelector.vue';

const axios = require('axios').create();

const NOT_PUBLISHED = 0;
const NOT_RECEIVED = 1;
const READ = 3;

export default {
	name: 'AccomodationUpdateForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin, addressHelperMixin],
	components: {
		TransportTypeSelector,
		'v-date-picker': DatePicker,
		InputDateTimePicker
	},
	props: {
		item: {
			type: Object,
			required: true,
			default: null
		},
		displayAddBtn: {
			type: Boolean,
			required: false,
			default: true
		},
		action: {
			type: String,
			required: true,
			default: 'UPDATE' // ADD, UPDATE, VIEW
		}
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		categoryOptions() {
			let menus = this.FormMenu(1403);

			return menus;
		},
		co2TypeOptions() {
			let menus = this.FormMenu(1408);

			return menus;
		},
		boatOptions() {
			let menus = this.FormMenu(1404);

			return menus;
		},
		busOptions() {
			let menus = this.FormMenu(1405);

			return menus;
		},
		carOptions() {
			let menus = this.FormMenu(1406);

			return menus;
		},
		otherOptions() {
			let menus = this.FormMenu(1407);

			return menus;
		},
		travelTypeOptions() {
			return [...this.boatOptions, ...this.busOptions, ...this.carOptions, ...this.otherOptions];
		},
		hotelOptions() {
			let menus = this.FormMenu(1415);

			return menus;
		},
		houseOptions() {
			let menus = this.FormMenu(1417);

			return menus;
		},
		appartOptions() {
			let menus = this.FormMenu(1416);

			return menus;
		},
		accomodationTypeOptions() {
			return [...this.hotelOptions, ...this.houseOptions, ...this.appartOptions];
		},
		bookingStatusOptions() {
			return [
				{ value: 0, text: this.FormMSG(32, 'Hire') },
				{ value: 1, text: this.FormMSG(33, 'Rental') }
			];
		},
		statusOptions() {
			return [
				{ value: 0, text: this.FormMSG(39, 'Not published') },
				{ value: 1, text: this.FormMSG(40, 'Not received') },
				{ value: 2, text: this.FormMSG(41, 'Received (not read)') },
				{ value: 3, text: this.FormMSG(42, 'Read') },
				{ value: 4, text: this.FormMSG(43, 'Signed') }
			];
		}
	},
	data() {
		return {
			accomodation: {
				endTime: '00:00',
				startTime: '00:00'
			},
			masks: {
				input: 'DD/MM/YYYY'
			},
			rangeHour: {},
			rangeMinute: {},
			WORKTYPE: {},
			isSubmitted: false,
			acceptFiles: '.jpg,.png,.gif,.pdf,.xlsx,.xls,.doc,.docx,.txt,.docs,.odt,.ods,.ppt,.pptx,.tar,.zip,.rar',
			unknownDevice: false,
			eventFile: null,
			uploadUrl: '/upload',
			parentType: 'accomodation_delivery',
			parentSubType: 'images',
			originalAccommodation: null
		};
	},
	created() {
		this.setActionInputDateTimePicker();
		this.setOriginalAccommodation();
	},
	methods: {
		async submitForm(e) {
			e.preventDefault();
			let message = '';
			this.isSubmitted = true;

			this.$v.$touch();
			if (this.invalid(this.$v.$invalid)) {
				return;
			}

			let updateAccomodation = _.cloneDeep(this.accomodation);
			let accommodationInput = {};

			if (
				this.originalAccommodation.startDate.getTime() === updateAccomodation.startDate.getTime() &&
				this.originalAccommodation.startTime === updateAccomodation.startTime &&
				this.originalAccommodation.endDate.getTime() === updateAccomodation.endDate.getTime() &&
				this.originalAccommodation.endTime === updateAccomodation.endTime &&
				_.isNil(this.eventFile) &&
				updateAccomodation.confirmation.length > 0 &&
				(this.originalAccommodation.toEmail === true || this.originalAccommodation.toSms === true || this.originalAccommodation.toWhatsApp === true)
			) {
				message = this.FormMSG(45, 'No data changed to save or to publish.');
				this.createToastForMobile(this.FormMSG(44, 'Warning'), message, '', 'warning');
				this.$emit('accomodation-update-form:close-modal', true);

				return;
			} else {
				let dataUpdated = false;
				// update data
				if (
					this.originalAccommodation.startDate.getTime() !== updateAccomodation.startDate.getTime() ||
					this.originalAccommodation.startTime !== updateAccomodation.startTime ||
					this.originalAccommodation.endDate.getTime() !== updateAccomodation.endDate.getTime() ||
					this.originalAccommodation.endTime !== updateAccomodation.endTime
				) {
					this.$emit('accomodation-update-form:waiting', true);
					if (
						this.originalAccommodation.startDate.getTime() !== updateAccomodation.startDate.getTime() ||
						this.originalAccommodation.startTime !== updateAccomodation.startTime
					) {
						accommodationInput.startDate = moment(updateAccomodation.startDate).format('YYYY-MM-DD') + 'T' + updateAccomodation.startTime + ':00Z';
					}

					if (
						this.originalAccommodation.endDate.getTime() !== updateAccomodation.endDate.getTime() ||
						this.originalAccommodation.endTime !== updateAccomodation.endTime
					) {
						accommodationInput.endDate = moment(updateAccomodation.endDate).format('YYYY-MM-DD') + 'T' + updateAccomodation.endTime + ':00Z';
					}
					accommodationInput.numberOfNights = moment(updateAccomodation.endDate).diff(moment(updateAccomodation.startDate), 'days');

					await updateAccomodationDelivery(+updateAccomodation.id, accommodationInput).then((result) => {
						if (result) {
							message = this.FormMSG(46, 'Accommodation delivery updated successfully.');
							this.createToastForMobile(this.FormMSG(47, 'Success'), message, '', 'success');
							if (_.isNil(this.eventFile)) {
								this.$emit('accomodation-update-form:waiting', false);
								this.$emit('accomodation-update-form:close-modal', { ...accommodationInput, index: updateAccomodation.index });
							}
						}
					});

					dataUpdated = true;
				}

				if (!_.isNil(this.eventFile)) {
					this.$emit('accomodation-update-form:waiting', true);
					this.upload(+updateAccomodation.id).then(async (response) => {
						if (response) {
							// response.confirmation, response.fileName
							// update data
							await updateAccomodationDelivery(+updateAccomodation.id, {
								confirmation: response.confirmation,
								fileName: response.fileName
							}).then((result) => {
								if (result) {
									message = this.FormMSG(48, 'Document saved successfully.');
									this.createToastForMobile(this.FormMSG(47, 'Success'), message, '', 'success');
									if (this.accomodation.status > NOT_PUBLISHED) {
										this.$emit('accomodation-update-form:waiting', false);
										this.$emit('accomodation-update-form:close-modal', true);
									}
								}
							});
						}
					});

					dataUpdated = true;
				}

				if (this.accomodation.status === NOT_PUBLISHED) {
					// @TODO publish data
					this.$emit('accomodation-update-form:waiting', true);
					await publishAccomodationDelivery(+updateAccomodation.id, {
						toEmail: updateAccomodation.toEmail,
						toSms: updateAccomodation.toSms,
						toWhatsApp: updateAccomodation.toWhatsApp
					}).then((result) => {
						if (result) {
							message = this.FormMSG(50, 'This accommodation has been published successfully.');
							this.createToastForMobile(this.FormMSG(47, 'Success'), message, '', 'success');
							this.$emit('accomodation-update-form:waiting', false);
							this.$emit('accomodation-update-form:close-modal', { status: NOT_RECEIVED, index: updateAccomodation.index });
						}
					});
				}

				if (!dataUpdated) {
					this.$emit('accomodation-update-form:close-modal', true);
				} else {
					this.$emit('accomodation-update-form:refresh');
				}
			}
		},
		handleStartDate(payload) {
			if (this.accomodation.endDate.getTime() < this.accomodation.startDate.getTime()) {
				this.accomodation.endDate = payload.date;
			}
		},
		handleEndDate(payload) {
			if (this.accomodation.endDate.getTime() < this.accomodation.startDate.getTime()) {
				this.accomodation.startDate = payload.date;
			}
		},
		endTimeValid(payload) {
			let startDate = moment(this.accomodation.startDate).utc().format('YYYY-MM-DD');
			startDate += 'T' + this.accomodation.startTime + ':00Z';

			let endDate = moment(this.accomodation.endDate).utc().format('YYYY-MM-DD');
			endDate += 'T' + payload + ':00Z';

			return new Date(endDate).getTime() > new Date(startDate).getTime();
		},
		handleChangeHourMin(payload) {
			this.accomodation = {
				...this.accomodation,
				[payload.fieldName]: payload.hourValid
			};
		},
		setActionInputDateTimePicker() {
			this.WORKTYPE = useWorktype;
			this.rangeHour = useRangeHour;
			this.rangeMinute = useRangeMinute;
		},
		onChangeEmail(payload) {
			if (payload === true) {
				this.unknownDevice = false;
				this.accomodation.toEmail = true;
			} else {
				if (this.accomodation.toSms === false && this.accomodation.toWhatsApp === false) {
					this.unknownDevice = true;
				}
			}
		},
		onChangeSms(payload) {
			if (payload === true) {
				this.unknownDevice = false;
				this.accomodation.toSms = true;
			} else {
				if (this.accomodation.toEmail === false && this.accomodation.toWhatsApp === false) {
					this.unknownDevice = true;
				}
			}
		},
		onChangeWhatsApp(payload) {
			if (payload === true) {
				this.unknownDevice = false;
				this.accomodation.toWhatsApp = true;
			} else {
				if (this.accomodation.toEmail === false && this.accomodation.toSms === false) {
					this.unknownDevice = true;
				}
			}
		},
		handleChangeFileToUploaded(e) {
			if (e.type === 'drop') {
				this.eventFile = e.dataTransfer.files[0];
			} else {
				this.eventFile = e.target.files[0];
			}

			this.accomodation.fileName = this.eventFile.name;

			const fileReader = new FileReader();
			fileReader.readAsDataURL(this.eventFile);
		},
		upload(deliveryId) {
			return new Promise((resolve, reject) => {
				let formData = new FormData();
				formData.append('uploadimage', this.eventFile);
				formData.append('fileName', this.eventFile.name);
				formData.append('fileType', this.eventFile.type);
				formData.append('parentId', deliveryId);
				formData.append('parentType', this.parentType);
				formData.append('parentSubType', this.parentSubType);

				let urlStr = process.env.VUE_APP_GQL + this.uploadUrl;
				axios
					.post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((result) => {
						this.accomodation.confirmation = result.data.substring(0, result.data.lastIndexOf('.'));
					})
					.catch((error) => {
						console.log({ error });
					});

				setTimeout(() => {
					resolve(this.accomodation);
					reject(false);
				}, 250);
			});
		},
		getTextOption(value, options) {
			const text = options.filter((option) => option.value === value);

			return text.length > 0 ? text[0].text : '';
		},
		// async publishAccommodation() {},
		getPublishedDate() {
			return moment(this.accomodation.publishedOn).utc().format('DD/MM/YYYY HH:mm');
		},
		getDeviceName() {
			let devices = [];
			if (this.accomodation.toEmail) {
				devices.push(this.FormMSG(28, 'Email'));
			}

			if (this.accomodation.toSms) {
				devices.push(this.FormMSG(29, 'Text message'));
			}

			if (this.accomodation.toWhatsApp) {
				devices.push(this.FormMSG(30, 'WhatsApp'));
			}

			return devices.join(', ');
		},
		getExtensionFile(filename) {
			return filename.substring(filename.lastIndexOf('.') + 1);
		},
		getFileName(filename) {
			return filename.substring(0, filename.lastIndexOf('.'));
		},
		async viewAttachment() {
			const fileToPreview = {
				...this.accomodation,
				src: `${process.env.VUE_APP_GQL}/images/${this.accomodation.confirmation}`,
				xid: this.accomodation.confirmation,
				ext: this.getExtensionFile(this.accomodation.fileName)
			};

			this.$previewImages({
				name: 'mydocumentdetails-previewer-elem',
				images: [fileToPreview],
				focusIndex: 0,
				options: {
					presentationMode: 'doc',
					// showStatusFileTag: true,
					hideCommentButton: false,
					hideDisLikeButton: false,
					hideLikeDislike: false,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: true
				}
			});
			if (this.action === 'VIEW') {
				if (+this.accomodation.status !== READ) {
					await updateAccomodationDelivery(+this.accomodation.id, {
						status: READ
					}).then((result) => {
						if (result) {
							this.$emit('accomodation-update-form:refresh');
							// this.$emit('accomodation-update-form:close-modal', { ...accommodationInput, index: updateAccomodation.index });
						}
					});
				}
			}
		},
		removeAttachment() {
			const action = async () => {
				this.accomodation.fileName = '';
				this.accomodation.confirmation = '';
			};
			this.confirmModal(action, this.FormMSG(38, 'Are you sure to remove this attachment ?'), 'danger');
		},
		invalid(isInvalid) {
			this.unknownDevice = false;
			if (
				(this.accomodation.toEmail === false && this.accomodation.toSms === false && this.accomodation.toWhatsApp === false && isInvalid === true) ||
				(this.accomodation.toEmail === false && this.accomodation.toSms === false && this.accomodation.toWhatsApp === false && isInvalid === false)
			) {
				this.unknownDevice = true;
			}

			return this.unknownDevice;
		},
		setOriginalAccommodation() {
			this.originalAccommodation = _.cloneDeep(this.accomodation);
		},
		getLiteralDate(date, time) {
			const langCurrent = this.currentLang;
			const fullLiteralDate = moment(date)
				.utc()
				.locale(`${langCurrent}`)
				.format(`dddd, Do MMMM YYYY [${this.FormMSG(73, 'at')}] ${time}`);

			return fullLiteralDate;
		}
	},
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					const startDate = value.startDate;
					const endDate = value.endDate;

					if (_.isString(startDate) && _.isString(endDate)) {
						value.startTime = startDate.split('T')[1].substring(0, 5);
						value.endTime = endDate.split('T')[1].substring(0, 5);
						value.startDate = new Date(startDate.split('T')[0]);
						value.endDate = new Date(endDate.split('T')[0]);
					}
					this.accomodation = _.cloneDeep(value);
				}
			},
			immediate: true
			// deep: true
		}
	},
	validations() {
		const fileToUploaded = {
			required
		};

		let mandatoryTravel = {
			accomodation: {
				endTime: {
					required,
					hourMinuteValid: this.endTimeValid
				}
			}
		};

		let mandatoryAccomodation = {
			accomodation: {
				endTime: {
					required,
					hourMinuteValid: this.endTimeValid
				}
			}
		};

		/**
		 * Disable attachment field mandatory mandatory
		 */
		// if (this.accomodation.confirmation.length === 0 && this.accomodation.acc.category === 1) {
		// 	mandatoryTravel.accomodation.fileToUploaded = fileToUploaded;
		// }

		// if (this.accomodation.confirmation.length === 0 && this.accomodation.acc.category === 0) {
		// 	mandatoryAccomodation.accomodation.fileToUploaded = fileToUploaded;
		// }

		return this.accomodation.acc.category === 1 ? mandatoryTravel : mandatoryAccomodation;
	}
};
</script>
<style lang="scss">
.content-form-text {
	display: flex;
	align-items: center;
	background: #f5f7f9;
	border: 1px solid #cad0d7;
	border-radius: 8px;
	color: rgba(6, 38, 62, 0.74);
	letter-spacing: 0.02em;
	min-height: 32px;
	padding: 4px 12px 4px 12px;
	font-size: 0.9rem;
}
.text-department {
	font-size: 0.85rem;
	color: rgba(6, 38, 62, 0.64);
	margin-top: -1px;
}
#total-distance {
	background-color: #f5f7f9;
}
</style>
