<template>
	<div :id="selectorRef" :ref="selectorRef">
		<label for="select-component" class="d-block" v-if="label">
			{{ label }}
		</label>
		<b-row>
			<b-col class="d-flex">
				<v-select
					:id="`${iconActions.length > 0 ? 'custom-select' : ''}`"
					v-model="selected"
					:placeholder="rendPlaceholder"
					:options="options"
					:label="labelField"
					:reduce="(option) => option[keyField]"
					:disabled="disabled"
					@input="handleChange"
					@search="handleSearch"
					class="grow-flex-auto"
				/>
				<div class="d-flex flex-row" :class="{ iconGroupBorderAppend: `${iconActions.length > 0}` }">
					<!-- <div :style="`width: ${defaultIconWidth}`" class="d-inline-flex justify-content-center align-items-center">aaa</div>
					<div :style="`width: ${defaultIconWidth}`" class="d-inline-flex justify-content-center align-items-center">aaa</div> -->
					<template v-for="data in iconActions">
						<template
							:style="`width: ${defaultIconWidth}`"
							class="d-inline-flex justify-content-center align-items-center"
							:slot="data.key"
							:title="data.title"
							v-b-tooltip.hover.top
							@click="data.function"
						>
							<slot :name="data.key" :item="data.item" />
						</template>
					</template>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import SelectorsMixin from '@/mixins/selectors.mixin';
import * as _ from 'lodash';

export default {
	name: 'BaseSelectorComponent',
	mixins: [SelectorsMixin],
	data() {
		return {
			defaultIconWidth: '34px'
		};
	},
	methods: {
		/**
		 * @param {Object} payload
		 */
		handleChange(payload) {
			if (!_.isNil(payload)) {
				this.$emit('change', payload.item);
			} else {
				this.$emit('unselected', this.selected.item);
				this.selected = null;
			}
		}
	}
};
</script>
<style lang="scss">
// .grow-flex-auto {
// 	flex: 1 1 100%;
// }

// #custom-select.vs--searchable .vs__dropdown-toggle {
// 	border: 1px solid #cad0d7;
// 	border-radius: 8px 0px 0px 8px;
// }
</style>
