<template>
	<div class="animated fadeIn" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<b-form @submit.prevent="submitForm">
			<b-row class="d-flex align-items-center pb-2">
				<b-col cols="6">
					<b-button size="md" variant="secondary" class="w-138-px" block v-if="enableBtnReport">{{ FormMSG(1, 'Reports') }}</b-button>
				</b-col>
				<b-col cols="6">
					<div class="d-flex justify-content-end align-items-center">
						<div class="text-booking font-weight-bold mr-3">{{ FormMSG(2, 'Booking n°') }}</div>
						<div class="number-booking py-2 px-4 font-weight-bold">{{ accomodation.reference }}</div>
					</div>
				</b-col>
			</b-row>
			<b-row class="mt-2 pb-2">
				<b-col>
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ FormMSG(3, 'General') }}
						</legend>
						<b-row :class="`${selectedUser.length > 0 ? '' : 'mb-2'}`">
							<b-col sm="12" md="12" lg="4" xl="4">
								<b-form-group :label="FormMSG(7, 'Person')">
									<treeselect
										v-model="selectedPerson"
										:multiple="false"
										:options="users"
										@select="personChanged"
										:disable-branch-nodes="true"
										:placeholder="FormMSG(8, 'Select a person')"
									>
										<div slot="option-label" slot-scope="{ node }">
											<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
										</div>
									</treeselect>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="4" xl="4">
								<b-form-group :label="FormMSG(9, 'Department')">
									<treeselect
										v-model="accomodation.department"
										:multiple="false"
										:options="departments"
										@select="departmentChanged"
										:disable-branch-nodes="true"
										:placeholder="FormMSG(10, 'Select persons from department')"
									>
										<div slot="option-label" slot-scope="{ node }">
											<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
										</div>
									</treeselect>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" lg="4" xl="4">
								<b-form-group :label="FormMSG(11, 'Description')">
									<b-form-input
										v-model="accomodation.description"
										:class="{
											'is-invalid': isSubmitted && $v.accomodation.description.$error
										}"
									/>
									<div v-if="isSubmitted && $v.accomodation.description.$error" class="invalid-feedback">
										{{ FormMSG(52, 'Please, description is required') }}
									</div>
								</b-form-group>
							</b-col>
						</b-row>
						<div v-if="selectedUser.length === 0 && isSubmitted === true" class="text-empty-person px-1 pb-3">
							* {{ FormMSG(51, 'Please, select one or more person(s) for this booking.') }}
						</div>
						<b-row v-if="selectedUser.length > 0">
							<b-col :cols="`${$screen.width <= 576 ? 5 : 2}`">
								<div class="d-flex">
									<h6>
										<b>{{ FormMSG(12, 'Person list') }}</b>
									</h6>
									<div class="w-10 ml-2" :style="`${$screen.width <= 576 ? 'margin-top: -4px' : 'margin-top: -1px'}`">
										<b-badge pill class="badge-variant">
											<span class="badge-notification" style="font-size: 0.785rem">{{ selectedUser.length }}</span>
										</b-badge>
									</div>
								</div>
							</b-col>
							<b-col :cols="`${$screen.width <= 576 ? 3 : 8}`">
								<div class="divider-dotted" />
							</b-col>
							<b-col :cols="`${$screen.width <= 576 ? 4 : 2}`" class="d-flex justify-content-end">
								<h6 class="d-flex align-items-center cursor-pointer" @click="clearAllSelectedUser()">
									<component :is="getLucideIcon('X')" color="#EA4E2C" class="mr-1" :size="18" :stroke-width="3" />
									<div style="margin-top: 2px">
										<b>{{ FormMSG(13, 'Clear All') }}</b>
									</div>
								</h6>
							</b-col>
						</b-row>
						<b-row class="px-3 mt-2" v-if="selectedUser.length > 0" :class="`${selectedUser.length > 0 ? 'mb-2' : ''}`">
							<b-col sm="2" class="d-flex mb-2" v-for="user in selectedUser" :key="user.value">
								<div class="d-inline-flex justify-content-center align-items-center" @click="removeUser(user.id)">
									<component
										:is="getLucideIcon('Trash')"
										color="#EA4E2C"
										class="mr-2 cursor-pointer"
										:size="20"
										:id="`tooltip-del-${user.id}`"
									/>
									<b-tooltip :target="`tooltip-del-${user.id}`" triggers="hover" placement="left">{{ FormMSG(47, 'Delete') }}</b-tooltip>
									<div
										:title="user.fullName"
										class="text-truncate"
										style="margin-top: 2px"
										:style="`${$screen.width <= 576 ? 'max-width: 270px;' : 'max-width: 140px;'}`"
										v-b-tooltip.hover.top
									>
										<b>{{ user.fullName }}</b>
										<div class="text-truncate" style="font-size: 0.85rem; color: rgba(6, 38, 62, 0.64); margin-top: -1px">
											{{ user.departmentName }}
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row class="mt-2 pb-2">
				<b-col>
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ FormMSG(4, 'Booking') }}
						</legend>
						<b-row>
							<b-col
								:sm="`${accomodation.category === 1 ? 6 : 12}`"
								:md="`${accomodation.category === 1 ? 6 : 12}`"
								:lg="`${accomodation.category === 1 ? 3 : 4}`"
								:xl="`${accomodation.category === 1 ? 3 : 4}`"
							>
								<b-form-group :label="FormMSG(14, 'Category')">
									<v-select
										v-model="accomodation.category"
										:options="categoryOptions"
										label="text"
										:reduce="(option) => option.value"
										:placeholder="FormMSG(15, 'Select a category')"
										:clearable="false"
										@input="categoryChanged"
									/>
								</b-form-group>
							</b-col>
							<b-col v-if="accomodation.category === 1">
								<transport-type-selector
									ref="transportTypeSelectorAccomodation"
									hide-freight-type
									:data-type="accomodation.type"
									:data-detail="accomodation.coTwoType"
									:data-additional-detail="accomodation.bookingStatus"
									@transportation-type-selector:change="handleTransportationTypeSelectorChange"
								/>
							</b-col>
							<b-col v-if="accomodation.category !== 1" sm="12" md="12" lg="4" xl="4">
								<b-form-group :label="FormMSG(16, 'Type')">
									<v-select
										v-model="accomodation.type"
										:options="accomodationTypeOptions"
										label="text"
										:reduce="(option) => option.value"
										:placeholder="FormMSG(17, 'Select a type')"
										:clearable="false"
										:selectable="selectableTypeItem"
										:class="{ 'is-invalid': isSubmitted && $v.accomodation.type.$error }"
									>
										<template #option="option">
											<div
												:class="`${
													(option.disabled && option.disabled === true) || (option.selectable && option.selectable === true)
														? 'text-bold'
														: 'text-color ml-3'
												}`"
											>
												{{ option.text }}
											</div>
										</template>
									</v-select>
									<div v-if="isSubmitted && !$v.accomodation.type.required" class="invalid-feedback">
										{{ FormMSG(53, 'Please, select a type') }}
									</div>
								</b-form-group>
							</b-col>
							<b-col v-if="accomodation.category !== 1" sm="12" md="12" lg="4" xl="4">
								<!--								<b-form-group :label="FormMSG(18, 'Co2 Type')" v-if="accomodation.category === 1">-->
								<!--									<v-select-->
								<!--										v-model="accomodation.coTwoType"-->
								<!--										:options="co2TypeOptions"-->
								<!--										label="text"-->
								<!--										:reduce="(option) => option.value"-->
								<!--										:placeholder="FormMSG(36, 'Select a Co2 Type')"-->
								<!--										:clearable="false"-->
								<!--										:class="{ 'is-invalid': isSubmitted && $v.accomodation.coTwoType.$error }"-->
								<!--									/>-->
								<!--									<div v-if="isSubmitted && !$v.accomodation.coTwoType.required" class="invalid-feedback">-->
								<!--										{{ FormMSG(54, 'Please, select a Co2 type') }}-->
								<!--									</div>-->
								<!--								</b-form-group>-->
								<b-form-group :label="FormMSG(44, 'No. of bedrooms')">
									<b-form-input
										v-model="accomodation.nbBedroom"
										type="number"
										step="1"
										min="0"
										placeholder="0"
										:class="{
											'is-invalid': isSubmitted && $v.accomodation.nbBedroom.$error
										}"
									/>
									<div v-if="isSubmitted && $v.accomodation.nbBedroom.$error" class="invalid-feedback">
										{{ FormMSG(55, 'Please, value must be greater than 0') }}
									</div>
								</b-form-group>
							</b-col>
							<!--							<b-col sm="6" md="6" lg="3" xl="3" v-if="accomodation.category === 1">-->
							<!--								<b-form-group>-->
							<!--									<label v-if="$screen.width > 576">&nbsp;</label>-->
							<!--									<b-form-radio-group v-model="accomodation.bookingStatus" :options="statusOptions" name="radio-options" plain />-->
							<!--								</b-form-group>-->
							<!--							</b-col>-->
						</b-row>
						<b-row>
							<b-col sm="6" md="6" lg="3" xl="3">
								<b-form-group :label="FormMSG(19, 'Start date')">
									<v-date-picker
										v-model="accomodation.startDate"
										:available-dates="{
											start: new Date(),
											end: null
										}"
										:locale="lang"
										:masks="masks"
										@dayclick="handleStartDate"
									>
										<template v-slot="{ inputValue, togglePopover }">
											<b-input-group>
												<b-form-input :value="inputValue" class="br-none" readonly />
												<b-input-group-append>
													<b-input-group-text
														class="cursor-pointer bg-color-white input-group-text-bg-white"
														@click="togglePopover()"
													>
														<component
															:is="getLucideIcon('Calendar')"
															color="rgba(6, 38, 62, .6)"
															:size="18"
															:stroke-width="2.25"
														/>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</template>
									</v-date-picker>
								</b-form-group>
							</b-col>
							<b-col sm="6" md="6" lg="3" xl="3">
								<b-form-group :label="FormMSG(20, 'Start time')">
									<input-date-time-picker
										:uiid="'accst'"
										:type="'text'"
										:placeholder="'hh:mm'"
										:item="accomodation.startTime"
										:nbHour="rangeHour[WORKTYPE.daily]"
										:nbMinute="rangeMinute[WORKTYPE.daily]"
										:isEditable="
											() => {
												return true;
											}
										"
										:fieldName="'startTime'"
										:workType="WORKTYPE.daily"
										iconColor="rgba(6, 38, 62, 0.6)"
										:iconStrokeWidth="2.25"
										:iconSize="18"
										iconBg="cursor-pointer bg-color-white input-group-text-bg-white"
										@change="handleChangeHourMin"
									/>
								</b-form-group>
							</b-col>
							<b-col sm="6" md="6" lg="3" xl="3">
								<b-form-group :label="FormMSG(21, 'End date')">
									<v-date-picker
										v-model="accomodation.endDate"
										:available-dates="{
											start: new Date(),
											end: null
										}"
										:locale="lang"
										:masks="masks"
										@dayclick="handleEndDate"
									>
										<template v-slot="{ inputValue, togglePopover }">
											<b-input-group>
												<b-form-input :value="inputValue" class="br-none" readonly />
												<b-input-group-append>
													<b-input-group-text
														class="cursor-pointer bg-color-white input-group-text-bg-white"
														@click="togglePopover()"
													>
														<component
															:is="getLucideIcon('Calendar')"
															color="rgba(6, 38, 62, .6)"
															:size="18"
															:stroke-width="2.25"
														/>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</template>
									</v-date-picker>
								</b-form-group>
							</b-col>
							<b-col sm="6" md="6" lg="3" xl="3">
								<b-form-group :label="FormMSG(22, 'End time')">
									<input-date-time-picker
										:uiid="'accet'"
										:type="'text'"
										:placeholder="'hh:mm'"
										:item="accomodation.endTime"
										:nbHour="rangeHour[WORKTYPE.daily]"
										:nbMinute="rangeMinute[WORKTYPE.daily]"
										:isEditable="
											() => {
												return true;
											}
										"
										:fieldName="'endTime'"
										:workType="WORKTYPE.daily"
										iconColor="rgba(6, 38, 62, 0.6)"
										:iconStrokeWidth="2.25"
										:iconSize="18"
										:isSubmitted="isSubmitted"
										:error="!$v.accomodation.endTime.hourMinuteValid"
										iconBg="cursor-pointer bg-color-white input-group-text-bg-white"
										@change="handleChangeHourMin"
									/>
									<div v-if="isSubmitted && !$v.accomodation.endTime.hourMinuteValid" class="invalid-feedback">
										{{ FormMSG(60, 'End time must be greater than start time') }}
									</div>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row class="pb-2">
							<b-col sm="12" md="12" lg="6" xl="6">
								<supplier-selector
									:title="'Supplier'"
									:label="FormMSG(23, 'Supplier')"
									:placeholder="FormMSG(24, 'Type to search ...')"
									:addlabel="FormMSG(25, 'Add')"
									:update-label="FormMSG(26, 'Update')"
									:supplier-id="accomodation.supplierId"
									:show-map="false"
									type="hotel"
									@supplier:unselected="onUnselected"
									@change="handleSupplierChanged"
									use-new-design
									version="1.0"
								/>
							</b-col>
							<b-col sm="12" md="12" lg="6" xl="6" :class="`${$screen.width <= 576 ? 'mt-3' : ''}`">
								<b-form-group :label="FormMSG(62, 'Attachment')">
									<b-form-file
										v-model="accomodation.fileToUploaded"
										ref="accomodation-file"
										class="mb-2"
										:accept="acceptFiles"
										:placeholder="FormMSG(63, 'Choose a file or drop it here ...')"
										:browse-text="FormMSG(64, 'Browse')"
										:drop-placeholder="FormMSG(65, 'Drop file here ...')"
										@change="handleChangeFileToUploaded"
									/>
									<!-- :class="{ 'is-invalid': isSubmitted && $v.accomodation.fileToUploaded.$error }" -->
									<!-- <div v-if="isSubmitted && !$v.accomodation.fileToUploaded.required" class="invalid-feedback">
										{{ FormMSG(66, 'Please, a document is required') }}
									</div> -->
								</b-form-group>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row class="mt-2 pb-2">
				<b-col>
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ accomodation.category === 1 ? FormMSG(5, 'Route') : FormMSG(43, 'Place') }}
						</legend>
						<div v-if="accomodation.category === 1">
							<google-distance
								:edit-data="accomodation"
								:from-location-label="FormMSG(28, 'From address')"
								:to-location-label="FormMSG(27, 'To address')"
								field-from="fromAddress"
								field-to="toAddress"
								field-total-distance="totalDistance"
								:to-location-error-message="FormMSG(56, 'Please, arrival point is required')"
								:from-location-error-message="FormMSG(57, 'Please, starting point is required')"
								:inline-validator="true"
								:is-submitted="isSubmitted"
								@change="handleDistanceChanged"
								@google-distance:loading="onGoogleLoading"
							/>
						</div>
						<b-row v-else>
							<b-col sm="6">
								<b-form-group :label="FormMSG(45, 'Accommodation name')">
									<b-form-input
										type="text"
										v-model="accomodation.name"
										:class="{
											'is-invalid': isSubmitted && $v.accomodation.name.$error
										}"
									/>
									<div v-if="isSubmitted && $v.accomodation.name.$error" class="invalid-feedback">
										{{ FormMSG(58, 'Please, accommodation name is required') }}
									</div>
								</b-form-group>
							</b-col>
							<b-col sm="6">
								<b-form-group :label="FormMSG(46, 'Address')">
									<v-address
										:params="{ title: FormMSG(48, 'Accomodation Address') }"
										:address-id="accomodation.addressId"
										:is-submitted="isSubmitted"
										:error="$v.accomodation.addressId.$error"
										@change="handleDynamicAddressChange"
										@delete="handleDynamicAddressDelete"
										@address-maplink-change="handleAddressMapLinkChange"
									/>
									<div v-if="isSubmitted && $v.accomodation.addressId.$error" class="invalid-feedback">
										{{ FormMSG(61, 'Please, enter an address valid') }}
									</div>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col sm="12" md="12" lg="3" xl="3" v-if="accomodation.category === 1">
								<b-form-group :label="FormMSG(29, 'Total distance')">
									<b-input-group>
										<b-form-input
											type="number"
											min="0"
											step="0.001"
											v-model="accomodation.totalDistance"
											placeholder="0"
											:class="{
												'is-invalid': isSubmitted && $v.accomodation.totalDistance.$error
											}"
											:readonly="readonlyTotalDistance"
										/>
										<b-input-group-append>
											<b-input-group-text style="background: #47c7bf">
												<span class="font-weight-bold" style="color: #fff">{{ distanceUnit }}</span>
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
									<div v-if="isSubmitted && $v.accomodation.totalDistance.$error" class="invalid-feedback">
										{{ FormMSG(55, 'Please, value must be greater than 0') }}
									</div>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="12" :lg="`${accomodation.category === 1 ? 9 : 12}`" :xl="`${accomodation.category === 1 ? 9 : 12}`">
								<b-form-group :label="FormMSG(30, 'Special instructions')">
									<b-form-input v-model="accomodation.specialInstruction" />
								</b-form-group>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row class="mt-2 pb-2">
				<b-col>
					<fieldset
						class="my-0 py-0 pb-0"
						:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
						style="background-color: rgba(248, 249, 251, 0.5)"
					>
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
							{{ FormMSG(6, 'Communication') }}
						</legend>
						<b-row>
							<b-col sm="5" md="5" lg="3" xl="3" class="d-flex justify-content-start align-items-center">
								<h6>
									<b>{{ FormMSG(31, 'Send type') }}</b>
								</h6>
							</b-col>
							<b-col sm="2" md="2" lg="6" xl="6">
								<div class="divider-dotted" :style="`${$screen.width > 576 ? 'margin: 12px 0 0 0' : 'margin: 4px 0 8px 0'}`" />
							</b-col>
							<b-col sm="4" md="5" lg="3" xl="3" class="d-flex justify-content-end align-items-start">
								<b-button
									size="md"
									variant="outline-secondary"
									class="w-138-px"
									v-if="currentAccommodation && currentAccommodation.publishedOn === '0001-01-01T00:00:00Z'"
									@click="publishAccommodation"
									block
								>
									<div class="d-flex justify-content-center align-items-center">
										<b-spinner v-show="watingPublishDelivery" small />
										<div :class="`${watingPublishDelivery ? 'pl-2' : ''}`" style="margin-top: 1px">
											{{ FormMSG(32, 'Send details') }}
										</div>
									</div>
								</b-button>
							</b-col>
						</b-row>
						<b-row class="d-flex justify-content-center" :class="`${action === 'ADD' ? 'pb-2' : ''}`">
							<b-col sm="12" md="12" lg="8" xl="6" offset-sm="0">
								<b-row>
									<b-col sm="4" :class="`${$screen.width > 576 ? 'd-flex justify-content-center' : 'd-flex justify-content-start'}`">
										<b-form-checkbox size="lg" id="toEmail" v-model="accomodation.toEmail" class="pj-cb pb-1" @change="onChangeEmail">
											{{ FormMSG(33, 'Email') }}
										</b-form-checkbox>
									</b-col>
									<b-col sm="4" :class="`${$screen.width > 576 ? 'd-flex justify-content-center' : 'd-flex justify-content-start'}`">
										<b-form-checkbox size="lg" id="toSms" v-model="accomodation.toSms" class="pj-cb pb-1" @change="onChangeSms">
											{{ FormMSG(34, 'Text message') }}
										</b-form-checkbox>
									</b-col>
									<b-col sm="4" :class="`${$screen.width > 576 ? 'd-flex justify-content-center' : 'd-flex justify-content-start'}`">
										<b-form-checkbox
											size="lg"
											id="toWhatsApp"
											v-model="accomodation.toWhatsApp"
											class="pj-cb pb-1"
											@change="onChangeWhatsApp"
										>
											{{ FormMSG(35, 'WhatsApp') }}
										</b-form-checkbox>
									</b-col>
								</b-row>
								<div class="invalid-feedback text-center pb-2" style="margin-top: -2px" v-if="unknownDevice">
									* {{ FormMSG(69, 'Please, select one or more device(s) to send the details.') }}
								</div>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					class="d-flex justify-content-center mt-2"
					sm="4"
					md="4"
					lg="4"
					xl="4"
					offset-sm="5"
					offset-md="5"
					offset-lg="5"
					offset-xl="5"
					:style="`display: ${displayAddBtn ? 'block' : 'none'} !important;`"
				>
					<b-button ref="submit" type="submit" size="md" variant="outline-primary" class="w-138-px" block>{{ FormMSG(59, 'Save') }}</b-button>
				</b-col>
			</b-row>
		</b-form>
		<accomodation-success v-model="isAccomodationAdded" @accomodation-success-modal:closed="onSuccessClosed" />
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength, decimal } from 'vuelidate/lib/validators';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import _ from 'lodash';
import { getUsers } from '@/cruds/users.crud';
import { getDepartments } from '@/cruds/department.crud';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import InputDateTimePicker from '@/components/InputDateTimePicker/InputDateTimePicker';
import { useWorktype, useRangeHour, useRangeMinute } from '../../utils/utils';
import SupplierSelector from '@/components/SupplierSelector';
import BaseSelect from '@/components/Selectors/Main';
import GoogleDistance from '@/components/GoogleDistance';
import { store } from '@/store';
import { noTilde } from '~utils';
import { recalculateDistanceValueByUnit, recalculateDistanceValueToKm } from '@/shared/google/helpers';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';
import { greaterThanZero, hourMinuteValid, generateSecureId } from '@/shared/utils';
import moment from 'moment';
import { addAccomodation, updateAccomodation, delAccomodation, updateAccomodationDeliveries, publishAccomodationDeliveries } from '@/cruds/accomodation.crud';
import AccomodationSuccess from '@/components/Accomodation/SuccessModal';
import TransportTypeSelector from '@/modules/transports/components/TransportTypeSelector.vue';

const axios = require('axios').create();

export default {
	name: 'AccomodationForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: {
		Treeselect,
		'v-date-picker': DatePicker,
		InputDateTimePicker,
		SupplierSelector,
		'b-select': BaseSelect,
		GoogleDistance,
		'v-address': MainAddressLocation,
		AccomodationSuccess,
		TransportTypeSelector
	},
	props: {
		displayBtnSubmit: {
			type: Boolean,
			required: false,
			default: true
		},
		action: {
			type: String,
			required: true,
			default: 'ADD' // ADD, UPDATE
		},
		enableBtnReport: {
			type: Boolean,
			required: false,
			default: true
		},
		displayAddBtn: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	computed: {
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		statusOptions() {
			return [
				{ value: 0, text: this.FormMSG(37, 'Hire') },
				{ value: 1, text: this.FormMSG(38, 'Rental') }
			];
		},
		categoryOptions() {
			let menus = this.FormMenu(1403);

			menus.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return menus;
		},
		co2TypeOptions() {
			let menus = this.FormMenu(1408);

			menus.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return menus;
		},
		boatOptions() {
			let menus = this.FormMenu(1404);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 139,
					text: this.FormMSG(39, 'Boat'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		busOptions() {
			let menus = this.FormMenu(1405);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 140,
					text: this.FormMSG(40, 'Bus'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		carOptions() {
			let menus = this.FormMenu(1406);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 141,
					text: this.FormMSG(41, 'Car'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		otherOptions() {
			let menus = this.FormMenu(1407);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 142,
					text: this.FormMSG(42, 'Other'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		travelTypeOptions() {
			let menus = [];
			let headeroptions = [
				{
					value: 139,
					text: this.FormMSG(39, 'Boat')
				},
				{
					value: 140,
					text: this.FormMSG(40, 'Bus')
				},
				{
					value: 141,
					text: this.FormMSG(41, 'Car')
				}
			];

			headeroptions.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			let principalOptions = [];

			for (let i = 0; i < headeroptions.length; i++) {
				if (headeroptions[i].value === 139) {
					principalOptions.push(...this.boatOptions);
				} else if (headeroptions[i].value === 140) {
					principalOptions.push(...this.busOptions);
				} else if (headeroptions[i].value === 141) {
					principalOptions.push(...this.carOptions);
				}
			}

			menus = [...principalOptions, ...this.otherOptions];

			return menus;
		},
		hotelOptions() {
			let menus = this.FormMenu(1415);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 149,
					text: this.FormMSG(49, 'Hotel'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		houseOptions() {
			let menus = this.FormMenu(1417);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 150,
					text: this.FormMSG(50, 'House'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		appartOptions() {
			let menus = this.FormMenu(1416);
			if (menus.length === 1) {
				menus[0].selectable = true;
			}

			return menus;
		},
		accomodationTypeOptions() {
			let menus = [];
			let headeroptions = [
				{
					value: 149,
					text: this.FormMSG(49, 'Hotel')
				},
				{
					value: 150,
					text: this.FormMSG(50, 'House')
				}
			];

			headeroptions.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			let principalOptions = [...this.appartOptions];

			for (let i = 0; i < headeroptions.length; i++) {
				if (headeroptions[i].value === 149) {
					principalOptions.push(...this.hotelOptions);
				} else if (headeroptions[i].value === 150) {
					principalOptions.push(...this.houseOptions);
				}
			}

			menus = [...principalOptions];

			return menus;
		}
	},
	data() {
		return {
			selectedPerson: 0,
			users: [],
			departments: [],
			selectedUser: [],
			statusSelected: 0,
			masks: {
				input: 'DD/MM/YYYY'
			},
			rangeHour: {},
			rangeMinute: {},
			WORKTYPE: {},
			accomodation: {
				name: '',
				reference: 0,
				description: '',
				type: 0,
				supplierId: 0,
				toEmail: false,
				toSms: false,
				toWhatsApp: false,
				coTwoType: 0,
				department: 0,
				fromAddress: '',
				toAddress: '',
				totalDistance: 0,
				specialInstruction: '',
				category: 1,
				bookingStatus: 0,
				startDate: new Date(),
				startTime: '00:00',
				endDate: new Date(),
				endTime: '00:00',
				nbBedroom: 0,
				addressId: 0,
				fileToUploaded: null
			},
			dontRecalculate: false,
			incRecalculate: 0,
			isSubmitted: false,
			eventFile: null,
			uploadUrl: '/upload',
			parentType: 'accomodation',
			parentSubType: 'images',
			userIds: [],
			acceptFiles: '.jpg,.png,.gif,.pdf,.xlsx,.xls,.doc,.docx,.txt,.docs,.odt,.ods,.ppt,.pptx,.tar,.zip,.rar',
			isAccomodationAdded: false,
			currentAccommodation: null,
			unknownDevice: false,
			readonlyTotalDistance: false,
			watingPublishDelivery: false
		};
	},
	async created() {
		await this.getUsers();
		await this.getDepartments();
		this.setActionInputDateTimePicker();
	},
	methods: {
		handleTransportationTypeSelectorChange({ type, detail, additionalDetail }) {
			console.log({ detail, additionalDetail });
			this.accomodation.type = type;
			this.accomodation.coTwoType = detail;
			this.accomodation.bookingStatus = additionalDetail;
		},
		async submitForm(e) {
			e.preventDefault();
			this.isSubmitted = true;

			this.$v.$touch();

			let transportIsInvalid = false;

			if (this.accomodation.category === 1) {
				transportIsInvalid = this.$refs['transportTypeSelectorAccomodation'].isInvalid();
			}

			if (this.$v.$invalid || this.selectedUser.length === 0 || transportIsInvalid) {
				return;
			}

			if (this.action === 'ADD') {
				let message = '';
				this.$emit('accomodation-form:waiting', true);
				// 1- initialize accomodation
				const newAccomodation = await this.addAccomodation();

				// 2- update accomodation with right values
				let updateAccomodation = this.accomodation;
				updateAccomodation.reference = this.accomodation.reference = newAccomodation.reference;
				updateAccomodation.startDate = moment(updateAccomodation.startDate).format('YYYY-MM-DD') + 'T' + updateAccomodation.startTime + ':00Z';
				updateAccomodation.endDate = moment(updateAccomodation.endDate).format('YYYY-MM-DD') + 'T' + updateAccomodation.endTime + ':00Z';
				updateAccomodation.department = 0;
				if (this.accomodation.category === 1) {
					updateAccomodation.totalDistance = parseFloat(updateAccomodation.totalDistance);
					updateAccomodation.name = 'acc-' + newAccomodation.id;
				} else {
					updateAccomodation.nbBedroom = +updateAccomodation.nbBedroom;
				}

				if (!_.isNil(this.eventFile)) {
					this.upload(+newAccomodation.id).then(async (response) => {
						if (response) {
							updateAccomodation.attachmentXid = response.attachmentXid;
							await this.updateAccomodationDeliveries(newAccomodation, updateAccomodation, response);
						}
					});
				} else {
					await this.updateAccomodationDeliveries(newAccomodation, updateAccomodation);
				}
			}
		},
		personChanged(payload) {
			if (this.selectedUser.findIndex((user) => +user.id === +payload.id) === -1) {
				this.selectedUser.push(payload);
				this.userIds.push(+payload.id);
			}
		},
		async getUsers() {
			await getUsers({ myProject: true }).then((users) => {
				users = users.map((user) => ({
					id: +user.id,
					label: user.fullName.trim() + ' (' + user.departmentName + ')',
					fullName: user.fullName.trim(),
					department: user.department,
					departmentName: user.departmentName.trim()
				}));
				this.users = [{ id: 0, label: this.FormMSG(8, 'Select a person') }, ...users];
			});
		},
		async departmentChanged(payload) {
			const users = await getUsers({ myProject: true }, null, +payload.value);
			users.forEach((selectedUser) => {
				if (this.selectedUser.findIndex((user) => +user.id === +selectedUser.id) === -1) {
					this.selectedUser.push(selectedUser);
					this.userIds.push(+selectedUser.id);
				}
			});
		},
		async getDepartments() {
			await getDepartments(false, false).then((departments) => {
				departments = departments.map((department) => ({
					id: +department.id,
					label: department.message,
					value: department.value
				}));
				this.departments = [{ id: 0, label: this.FormMSG(10, 'Select persons from department') }, ...departments];
			});
		},
		removeUser(id) {
			const index = this.selectedUser.findIndex((user) => parseInt(user.id) === +id);
			if (index !== -1) {
				this.selectedUser.splice(index, 1);
				this.userIds.splice(index, 1);
			}
		},
		clearAllSelectedUser() {
			this.selectedUser = [];
			this.userIds = [];
			this.selectedPerson = 0;
			this.accomodation.department = 0;
		},
		setActionInputDateTimePicker() {
			this.WORKTYPE = useWorktype;
			this.rangeHour = useRangeHour;
			this.rangeMinute = useRangeMinute;
		},
		handleChangeHourMin(payload) {
			this.accomodation[payload.fieldName] = payload.hourValid;
		},
		onUnselected() {},
		handleSupplierChanged(payload) {
			this.accomodation.supplierId = +payload.id;
		},
		handleDistanceChanged(payload) {
			this.accomodation = {
				...this.accomodation,
				...payload
			};

			this.setOriginalTotalDistance();
		},
		selectableTypeItem(item) {
			return _.isNil(item.disabled) || (!_.isNil(item.disabled) && item.disabled === false);
		},
		setOriginalTotalDistance() {
			this.incRecalculate++;
			if (
				!this.dontRecalculate &&
				this.incRecalculate === 2 &&
				!_.isNil(this.accomodation.originalTotalDistance) &&
				this.accomodation.originalTotalDistance > 0 &&
				this.accomodation.originalTotalDistance !== this.accomodation.totalDistance
			) {
				this.accomodation.totalDistance = recalculateDistanceValueByUnit(this.accomodation.originalTotalDistance);
				this.dontRecalculate = true;
			}
		},
		categoryChanged(payload) {
			if (payload === 0) {
				this.accomodation.type = null;
				this.accomodation.coTwoType = null;
				this.accomodation.bookingStatus = 0;
			} else if (payload === 1) {
				this.accomodation.type = 0;
				this.accomodation.coTwoType = 0;
				this.accomodation.bookingStatus = 0;
			}
			this.isSubmitted = false;
			//this.resetData();
		},
		resetData() {
			this.selectedUser = [];
			this.userIds = [];
			this.selectedPerson = 0;
			this.accomodation = {
				name: '',
				reference: 0,
				description: '',
				type: 0,
				supplierId: 0,
				toEmail: false,
				toSms: false,
				toWhatsApp: false,
				coTwoType: 0,
				department: 0,
				fromAddress: '',
				toAddress: '',
				totalDistance: 0,
				specialInstruction: '',
				category: this.accomodation.category,
				bookingStatus: 0,
				startDate: new Date(),
				startTime: '00:00',
				endDate: new Date(),
				endTime: '00:00',
				nbBedroom: 0,
				addressId: 0
			};
		},
		handleDynamicAddressChange(payload) {
			this.accomodation.addressId = +payload.id;
		},
		handleDynamicAddressDelete() {
			this.accomodation.addressId = 0;
		},
		handleAddressMapLinkChange(payload) {
			console.log({ mapAddress: payload });
		},
		handleStartDate(payload) {
			if (this.accomodation.endDate.getTime() < this.accomodation.startDate.getTime()) {
				this.accomodation.endDate = payload.date;
			}
		},
		handleEndDate(payload) {
			if (this.accomodation.endDate.getTime() < this.accomodation.startDate.getTime()) {
				this.accomodation.startDate = payload.date;
			}
		},
		endTimeValid(payload) {
			let startDate = moment(new Date(this.accomodation.startDate)).format('YYYY-MM-DD');
			startDate += 'T' + this.accomodation.startTime + ':00Z';

			let endDate = moment(new Date(this.accomodation.endDate)).format('YYYY-MM-DD');
			endDate += 'T' + payload + ':00Z';

			return new Date(endDate).getTime() > new Date(startDate).getTime();
		},
		async addAccomodation() {
			const accomodation = await addAccomodation().catch((e) => {
				console.error(e);
			});

			return accomodation;
		},
		async updateAccomodation(accomodationId, accomodation) {
			const updAccomodation = await updateAccomodation(accomodationId, accomodation);

			return updAccomodation;
		},
		handleChangeFileToUploaded(e) {
			if (e.type === 'drop') {
				this.eventFile = e.dataTransfer.files[0];
			} else {
				this.eventFile = e.target.files[0];
			}

			this.accomodation.fileName = this.eventFile.name;

			const fileReader = new FileReader();
			fileReader.readAsDataURL(this.eventFile);
		},
		upload(accomodationId) {
			return new Promise((resolve, reject) => {
				let formData = new FormData();
				formData.append('uploadimage', this.eventFile);
				formData.append('fileName', this.eventFile.name);
				formData.append('fileType', this.eventFile.type);
				formData.append('parentId', accomodationId);
				formData.append('parentType', this.parentType);
				formData.append('parentSubType', this.parentSubType);

				let urlStr = process.env.VUE_APP_GQL + this.uploadUrl;
				axios
					.post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((result) => {
						this.accomodation.attachmentXid = result.data.substring(0, result.data.lastIndexOf('.'));
					})
					.catch((error) => {
						console.log({ error });
					});

				setTimeout(() => {
					resolve(this.accomodation);
					reject(false);
				}, 250);
			});
		},
		onSuccessClosed() {
			this.isAccomodationAdded = false;
		},
		async publishAccommodation() {
			if (this.accomodation.toEmail === false && this.accomodation.toSms === false && this.accomodation.toWhatsApp === false) {
				this.unknownDevice = true;
			} else {
				this.$emit('accomodation-form:waiting', true);
				this.watingPublishDelivery = true;
				const accomodationDeliveries = await publishAccomodationDeliveries(+this.currentAccommodation.id, {
					toEmail: this.accomodation.toEmail,
					toSms: this.accomodation.toSms,
					toWhatsApp: this.accomodation.toWhatsApp
				}).catch((e) => {
					const message = this.FormMSG(70, 'An error has occurred during published the accomodation, please contact your administrator.');
					this.createToastForMobile(this.FormMSG(68, 'Error'), message, '', 'danger');
					this.watingPublishDelivery = false;
					this.$emit('accomodation-form:waiting', false);
				});

				if (accomodationDeliveries) {
					// update checkbox device
					await this.updateAccomodation(+this.currentAccommodation.id, {
						toEmail: this.accomodation.toEmail,
						toSms: this.accomodation.toSms,
						toWhatsApp: this.accomodation.toWhatsApp
					});

					const message = this.FormMSG(72, 'The accommodation has been published successfully.');
					this.createToastForMobile(this.FormMSG(71, 'Success'), message, '', 'success');
					this.$emit('accomodation-form:published', accomodationDeliveries);
					this.$emit('accomodation-form:close-modal', true);
					this.watingPublishDelivery = false;
					this.$emit('accomodation-form:waiting', false);
				}
			}
		},
		onChangeEmail(payload) {
			if (payload === true) {
				this.unknownDevice = false;
				this.accomodation.toEmail = true;
			} else {
				if (!_.isNil(this.currentAccommodation) && this.accomodation.toSms === false && this.accomodation.toWhatsApp === false) {
					this.unknownDevice = true;
				}
			}
		},
		onChangeSms(payload) {
			if (payload === true) {
				this.unknownDevice = false;
				this.accomodation.toSms = true;
			} else {
				if (!_.isNil(this.currentAccommodation) && this.accomodation.toEmail === false && this.accomodation.toWhatsApp === false) {
					this.unknownDevice = true;
				}
			}
		},
		onChangeWhatsApp(payload) {
			if (payload === true) {
				this.unknownDevice = false;
				this.accomodation.toWhatsApp = true;
			} else {
				if (!_.isNil(this.currentAccommodation) && this.accomodation.toEmail === false && this.accomodation.toSms === false) {
					this.unknownDevice = true;
				}
			}
		},
		onGoogleLoading(payload) {
			this.readonlyTotalDistance = payload;
		},
		async updateAccomodationDeliveries(newAccomodation, updateAccomodation, uploadedFIle = null) {
			const updatedAccomodation = await this.updateAccomodation(+newAccomodation.id, updateAccomodation).catch(async (e) => {
				await delAccomodation(+newAccomodation.id);

				this.$emit('accomodation-form:waiting', false);
				this.$emit('accomodation-form:close-modal', true);

				// trigger error message
				console.error({ errorOnUpdate: e });

				message = this.FormMSG(67, 'An error has occurred during created an accomodation, please contact your administrator.');
				this.createToastForMobile(this.FormMSG(68, 'Error'), message, '', 'danger');
			});

			// 3- create all deliveries
			if (updatedAccomodation) {
				this.currentAccommodation = updatedAccomodation;

				const accomodationDeliveries = await updateAccomodationDeliveries(
					+newAccomodation.id,
					this.userIds,
					updateAccomodation.startDate,
					updateAccomodation.endDate,
					updateAccomodation.attachmentXid,
					!_.isNil(uploadedFIle) ? uploadedFIle.fileName : null
				).catch(async (e) => {
					await delAccomodation(+newAccomodation.id);

					// trigger error message
					console.error({ errorOnDeliveriesCreation: e });

					this.$emit('accomodation-form:waiting', false);
					this.$emit('accomodation-form:close-modal', true);

					message = this.FormMSG(67, 'An error has occurred during created an accomodation, please contact your administrator.');
					this.createToastForMobile(this.FormMSG(68, 'Error'), message, '', 'danger');
				});

				if (accomodationDeliveries) {
					this.$emit('accomodation-form:added', accomodationDeliveries);
					this.$emit('accomodation-form:waiting', false);
					this.$emit('accomodation-form:hide-submit', true);
					this.isAccomodationAdded = true;
				}
			}
		}
	},
	validations() {
		const mandatoryTravel = {
			accomodation: {
				description: {
					required,
					min: minLength(3)
				},
				type: {
					required: false
				},
				coTwoType: {
					required: false
				},
				totalDistance: {
					required,
					decimal,
					min: greaterThanZero
				},
				endTime: {
					required,
					hourMinuteValid: this.endTimeValid
				}
				// fileToUploaded: {
				// 	required
				// }
			}
		};
		const mandatoryAccomodation = {
			accomodation: {
				description: {
					required,
					min: minLength(3)
				},
				type: {
					required
				},
				nbBedroom: {
					required,
					decimal,
					min: greaterThanZero
				},
				name: {
					required,
					min: minLength(3)
				},
				addressId: {
					required,
					decimal,
					min: greaterThanZero
				},
				endTime: {
					required,
					hourMinuteValid: this.endTimeValid
				}
				// fileToUploaded: {
				// 	required
				// }
			}
		};

		return this.accomodation.category === 1 ? mandatoryTravel : mandatoryAccomodation;
	}
};
</script>
<style lang="scss">
.divider-dotted {
	position: relative;
	margin: 8px 0 8px 0;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 0.145rem dotted rgba(226, 230, 235, 0.85);
}

.text-bold {
	font-weight: 600;
	font-size: 1rem;
}

.text-color {
	font-size: 12px !important;
	color: rgba(6, 38, 62, 0.84) !important;
}

.text-empty-person {
	margin-top: -8px;
	color: #ea4e2c;
	font-size: 13px;
	letter-spacing: 0.05em;
	font-weight: 600;
}
</style>
